import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import pandyasEvent from "../images/page/events/pandyasEvent.jpg";
import defaultpng from "../images/icons/default.jpg";


function UpcomingEvents(props) {

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  let data = [];

  let pandyas_item = {};

  pandyas_item.page_url = "pandyas";
  pandyas_item.image = pandyasEvent;
  pandyas_item.blog_title = "Pandyas Trail";
  pandyas_item.description  = "Take a break to enjoy the Early Pandya rock cut temples and hidden Jain caves of our region. We have Ms Deepika, an expert from Chennai. It's a van day trip and registration is compulsory! We finish by relaxing in Courtallam private falls and then coming back to Tirunelveli.";
  pandyas_item.short_description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLSen-3DWzOpFjidtTAe31KXuz-RNnWab-OuUaUTbrwX0caFl2A/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:20px;color:brown'></i><b>Register here!</b></a>";
  data.push(pandyas_item);

  return (
    <section className="mt-5">
      <div className="band">
      {data.map((item, ind) => {
      return (
      <div
        className={`item-${ind}`}  key={ind}
      >
        <a className="my-card upcoming">
          <section className="mt-5">
            <div
              className="thumb"
              style={{
                backgroundImage: `url(${
                  item?.image != null || "" ? item?.image : defaultpng
                })`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleImageClick(item?.image || defaultpng);
              }}
            ></div>
          </section>
          <article>
            <p className="m-0 mt-3 content" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
            <p className="m-0 mt-3" dangerouslySetInnerHTML={{ __html: item?.short_description }}></p>
          </article>
        </a>
      </div>
            );
          })}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
        <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Pandyas Trail Event"/>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default UpcomingEvents;
